import React, { useCallback, useState } from 'react'

import { Breadcrumbs, Button, Wizard } from 'components'

import { FormProvider, useForm } from 'react-hook-form'

import {
  GeneralInformation,
  Scale,
} from '../../components/PatrolForm/components/'

import { PatrolFormData } from 'domains/customer/screens/Patrol/components/PatrolForm/types'
import { calcTimestamp } from 'domains/customer/screens/Patrol/components/PatrolForm/components/GeneralInformation/utils'

import { joiResolver } from '@hookform/resolvers/joi'
import { patrolSchema } from 'domains/customer/screens/Patrol/schemas/patrolFormSchema'
import { getHours } from 'domains/customer/screens/Patrol/utilities/time'

import {
  AggregatedPatrol,
  Schedule,
  PatrolRequest,
} from 'services/patrol/types'

import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { buildPath, paths } from 'routes'

interface PatrolFormProps {
  patrol?: AggregatedPatrol
  onSubmit: (data: PatrolRequest) => void
}

const Patrol: React.FC<PatrolFormProps> = ({ patrol, onSubmit }) => {
  const { customer, account } = useCustomerContext()
  const customerName = customer?.name ?? ''
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)

  const form = useForm<PatrolFormData>({
    mode: 'onChange',
    resolver: joiResolver(patrolSchema),
    defaultValues: {
      id: patrol?.id,
      name: patrol?.name,
      note: patrol?.note,
      active: patrol?.active,
      schedules:
        patrol?.schedules.map((schedule) => ({
          dayOfWork: schedule.dayOfWork,
          periods: schedule.hours.map((hour) => {
            const [hours, minutes] = hour.split(':')

            return { hours, minutes }
          }),
        })) || [],
      account: {
        id: patrol?.account.id,
        aggregatedAccountName: [patrol?.account.code, patrol?.account.name]
          .filter(Boolean)
          .join(' - '),
        serviceTypeName: patrol?.account.serviceTypeName,
        tags: patrol?.account.tags,
        customer: patrol?.account.customer,
        patrimony: patrol?.account.patrimony,
      },
      startDate: patrol?.startDate,
      ...(patrol?.startDate && {
        startHours: getHours(patrol.startDate),
      }),
      endDate: patrol?.endDate,
      ...(patrol?.endDate && {
        endHours: getHours(patrol.endDate),
      }),
      reason: {
        tags: patrol?.tags,
      },
    },
  })

  const { handleSubmit, formState } = form

  const submit = useCallback(() => {
    const patrolData = form.getValues()

    const formattedSchedules = patrolData.schedules.map((schedule) => ({
      dayOfWork: schedule.dayOfWork,
      hours: schedule.periods.map(
        (period) => `${period.hours}:${period.minutes}`,
      ),
    }))

    const workDaySchedule = formattedSchedules.find(
      (scale) => scale.dayOfWork === 'WORKDAYS',
    )

    let unGroupedBusinessDaysScale: Schedule[] = []
    if (workDaySchedule) {
      unGroupedBusinessDaysScale = [
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
      ].map((dayOfWork) => {
        return {
          dayOfWork,
          hours: workDaySchedule.hours,
        }
      }) as Schedule[]
    }

    const {
      account,
      reason,
      startDate,
      startHours,
      endDate,
      endHours,
      active,
      ...rest
    } = patrolData

    const formattedStartDate = calcTimestamp(startDate, startHours)

    const formattedEndDate =
      endDate && endHours ? calcTimestamp(endDate, endHours) : undefined

    const payload: PatrolRequest = {
      ...rest,
      accountId: account.id,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      tags: reason.tags.map((tag) => ({ name: tag })),
      schedules: [
        ...formattedSchedules.filter((scale) => scale.dayOfWork !== 'WORKDAYS'),
        ...unGroupedBusinessDaysScale,
      ] as Schedule[],
      ...(patrol?.active !== active && {
        active,
      }),
    }

    onSubmit(payload)
  }, [form, onSubmit, patrol])

  return (
    <div className={styles.container}>
      <div className={styles.wrapperBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/home' },
            { title: 'Contas', href: paths.account.list },
            {
              title: 'Horários',
              href: buildPath({
                path: paths.account.details + '?tab=officeHour&page=1',
                params: { accountId: account?.id },
              }),
            },
            { title: customerName, href: '' },
          ]}
        />
        <div className={styles.divider} />
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(submit)}>
          <Wizard activeStepIndex={activeStep}>
            <Wizard.Step title="Informações gerais">
              <GeneralInformation />
            </Wizard.Step>
            <Wizard.Step title="Definição de horários">
              <Scale />
            </Wizard.Step>
          </Wizard>

          {activeStep === 0 ? (
            <>
              <Button
                type="secondary"
                buttonTitle="Voltar"
                htmlType="button"
                className={styles.previousButton}
                width="172px"
                onClick={() =>
                  navigate(
                    buildPath({
                      path: paths.account.details + '?tab=officeHour&page=1',
                      params: { accountId: account?.id },
                    }),
                  )
                }
              />

              <Button
                type="primary"
                htmlType="button"
                buttonTitle="Próximo"
                className={styles.nextButton}
                width="172px"
                onClick={() => setActiveStep(1)}
              />
            </>
          ) : (
            <>
              <Button
                type="secondary"
                htmlType="button"
                buttonTitle="Voltar"
                className={styles.previousButton}
                width="172px"
                onClick={() => setActiveStep(0)}
              />
              <Button
                type="primary"
                buttonTitle="Salvar"
                className={styles.nextButton}
                width="172px"
                disabled={!formState.isValid}
                onClick={submit}
              />
            </>
          )}
        </form>
      </FormProvider>
    </div>
  )
}

export default Patrol
