import React from 'react'

import { Combobox } from 'components/ComboboxV2/Combobox'

import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { ReactComponent as MinusIcon } from 'assets/svg/minus.svg'

import {
  DayOfWorkOptions,
  translatedScaleConfig,
} from 'domains/customer/utilities/dayOfWork'

import {
  generateHoursSlots,
  generateMinutesSlots,
} from 'domains/customer/screens/Patrol/utilities/time'

import { getRangeBetweenTimes } from 'domains/customer/screens/OfficeHour'

import { Schedules } from 'domains/customer/screens/Patrol/components/PatrolForm/types'

import styles from './styles.module.scss'

export interface ScaleTimeConfigProps {
  dayOfWork: DayOfWorkOptions
  periods: { hours: string; minutes: string }[]
  onChangeScale: (schedules: Schedules) => void
}

const ScheduleTimeConfig: React.FC<ScaleTimeConfigProps> = (
  props: ScaleTimeConfigProps,
) => {
  const { dayOfWork, periods, onChangeScale } = props

  return (
    <div className={styles.container} data-testid="scaleconfig">
      <div className={styles.dayWrapper}>
        <span>{`${translatedScaleConfig[dayOfWork]}`}</span>
      </div>

      <div className={styles.periodsWrapper}>
        {periods
          ?.sort(
            (a, b) =>
              new Date(`1970-01-01T${a.hours}:00`).getTime() -
              new Date(`1970-01-01T${b.hours}:00`).getTime(),
          )
          ?.map((period, index) => {
            return (
              <div key={index} className={styles.periodContainer}>
                <div className={[styles.hourWrapper].join(' ')}>
                  <span>Às</span>
                  <Combobox
                    id={`start-time-${index}${dayOfWork}`}
                    placeholder="Hora"
                    className={styles.customCombobox}
                    items={getRangeBetweenTimes({
                      slots: generateHoursSlots(),
                      startTime: periods[index - 1]?.hours,
                      endTime: period.hours,
                      startOffset: 0,
                    })}
                    value={period.hours}
                    onChange={(value) => {
                      const selectedValue = value as string

                      const updatedHours = [...periods]

                      updatedHours[index] = {
                        ...updatedHours[index],
                        hours: selectedValue,
                      }

                      onChangeScale({
                        dayOfWork,
                        periods: updatedHours,
                      })
                    }}
                  />

                  <span>:</span>
                  <Combobox
                    id={`end-time-${index}${dayOfWork}`}
                    placeholder="Minutos"
                    className={styles.customCombobox}
                    items={generateMinutesSlots()}
                    value={period ? period.minutes : ''}
                    disabled={!period}
                    onChange={(value) => {
                      const selectedValue = value as string

                      const updatedHours = [...periods]

                      updatedHours[index] = {
                        ...updatedHours[index],
                        minutes: selectedValue,
                      }

                      onChangeScale({
                        dayOfWork,
                        periods: updatedHours,
                      })
                    }}
                  />

                  {index === 0 ? (
                    <button
                      type="button"
                      data-testid="add-period"
                      onClick={() => {
                        onChangeScale({
                          dayOfWork,
                          periods: [...periods, { hours: '', minutes: '' }],
                        })
                      }}
                    >
                      <PlusIcon />
                    </button>
                  ) : (
                    <button
                      type="button"
                      data-testid="remove-period"
                      onClick={() => {
                        onChangeScale({
                          dayOfWork,
                          periods: periods.filter(
                            (_period, periodIndex) => periodIndex !== index,
                          ),
                        })
                      }}
                    >
                      <MinusIcon />
                    </button>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default ScheduleTimeConfig
