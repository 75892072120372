import React, { ChangeEvent, FC, useCallback, useState } from 'react'

import { useFormContext } from 'react-hook-form'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { ComboboxItem, Datepicker, Input, RadioGroup, Tags } from 'components'

import { ReactComponent as LinkIcon } from 'assets/svg/link.svg'

import { AggregatedAccount } from 'services/account/types'
import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'

import {
  PatrolAccount,
  PatrolFormData,
  Reason,
} from 'domains/customer/screens/Patrol/components/PatrolForm/types'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'

import { v4 as uuid } from 'uuid'

import {
  PatrolStatusLabel,
  handlePatrolStatusLabel,
  predefinedOptions,
} from '../GeneralInformation/utils'

import styles from './styles.module.scss'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { buildPath, paths } from 'routes'

const GeneralInformation: FC = () => {
  const { customer, patrimony } = useCustomerContext()
  const customerId = customer?.id ?? ''
  const patrimonyId = patrimony?.id ?? ''

  const { setValue, watch, register } = useFormContext<PatrolFormData>()

  const [status, setStatus] = useState<PatrolStatusLabel>(
    watch('active') ? 'Ativo' : 'Inativo',
  )

  const [searchAccount, setSearchAccount] = useState('')
  const {
    accounts: accountsOptions,
    isFetching: isFetchingAccounts,
    isError: isErrorAccounts,
    fetchNextAccountsPage,
  } = useGetAccounts(true, customerId, {
    name: searchAccount,
    patrimonyId,
  })

  const handleSeeMoreAccount = useCallback(() => {
    const account = watch('account')

    window.open(
      buildPath({
        path: paths.account.details + '?tab=patrimony',
        params: {
          accountId: account.id,
        },
      }),
    )
  }, [])

  const handleChangeScheduleDateInput = useCallback(
    (formKey: string, event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const date = convertStringToDate(value)
        setValue(formKey, date.getTime())
        return value
      } else {
        return value
      }
    },
    [setValue],
  )

  const predefinedOptionsCombobox: ComboboxItem<Reason>[] =
    predefinedOptions?.map((predefinedComment) => ({
      label: 'message',
      value: {
        id: uuid(),
        ...predefinedComment,
      },
    }))

  return (
    <div>
      <section className={styles.column}>
        <div className={styles.inputs}>
          {!!watch('id') && (
            <div className={styles.userActiveWrapper}>
              <div className={styles.wrapperRadio}>
                <RadioGroup
                  customStyle={styles.userActiveWrapper}
                  title="Situação"
                >
                  {['Ativo', 'Inativo'].map((option, key) => (
                    <RadioButton
                      name="active"
                      {...register('active')}
                      onChange={(e) => {
                        const status = handlePatrolStatusLabel(
                          e.target.value as PatrolStatusLabel,
                        )

                        setValue('active', status.value, {
                          shouldDirty: true,
                          shouldValidate: true,
                        })

                        setStatus(status.label)
                      }}
                      checked={option === status}
                      value={option}
                      key={key}
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>
          )}

          <Input
            label="Nome"
            id="name"
            name="name"
            autoComplete="off"
            {...register('name')}
            value={watch('name') || ''}
            onChange={(event) =>
              setValue('name', event.target.value, {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
          />

          <Combobox
            id="account"
            label={{ text: 'Conta' }}
            value={watch('account')?.aggregatedAccountName}
            items={parseDataToComboboxV2(
              accountsOptions || [],
              'aggregatedAccountName',
            )}
            {...register('account')}
            onChange={(selected) => {
              const account = selected as ComboboxItem<AggregatedAccount>

              const value = account.value

              const formAccount: PatrolAccount = {
                id: value.id,
                aggregatedAccountName: value.aggregatedAccountName,
                customer: { id: value.customer.id, name: value.customer.name },
                patrimony: {
                  id: value.patrimony.id,
                  name: value.patrimony.name,
                },
                serviceTypeName: value.serviceType?.name || '',
                tags: value.tags?.map((tag) => tag.name) || [],
              }

              setValue('account', formAccount, {
                shouldValidate: true,
                shouldDirty: true,
              })
            }}
            itemLabel={(item) => {
              const account = item as ComboboxItem<AggregatedAccount>

              return account.value.serviceType?.name || ''
            }}
            isLoading={isFetchingAccounts}
            isError={isErrorAccounts}
            onEndReached={fetchNextAccountsPage}
            onSearch={setSearchAccount}
          />

          <div className={styles.accountTagWrapper}>
            <small>Tag da conta</small>
            {!watch('account') ? (
              <span>Nenhuma conta selecionada</span>
            ) : (
              <>
                <Tags
                  keyId={'#'}
                  keyLabel={'#'}
                  expandTags
                  className={styles.tags}
                  data={
                    watch('account')?.tags?.map((tag, index) => ({
                      id: String(index),
                      name: tag,
                    })) || []
                  }
                />
              </>
            )}
          </div>

          <button
            className={[
              styles.learnMore,
              !watch('account')?.id && styles.disable,
            ].join(' ')}
            onClick={handleSeeMoreAccount}
          >
            <LinkIcon fill="currentColor" />
            <span>Visualizar conta</span>
          </button>

          <div
            className={styles.datePickerWrapper}
            data-testid="create-date-filter-wrapper"
          >
            <p className={styles.sectionLabel}>Data e hora inicial</p>
            <div className={styles.datePickerInnerWrapper}>
              <div className={styles.fieldsInput}>
                <span className={styles.dateLabel}>De</span>
                <Datepicker
                  id="startDate"
                  initialValueInput={
                    watch('startDate')
                      ? convertDateToString(new Date(watch('startDate')))
                      : undefined
                  }
                  initialDate={new Date()}
                  onChangeInput={(event) =>
                    handleChangeScheduleDateInput('startDate', event)
                  }
                  onChangeDate={(date) =>
                    setValue('startDate', date?.getTime(), {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                  selectEnd
                  {...register('startDate')}
                />
              </div>

              <div className={styles.fieldsInput}>
                <span className={styles.dateLabel}>às</span>
                <Input
                  id="startHours"
                  {...register('startHours')}
                  className={styles.hoursInput}
                  type="time"
                  autoComplete="off"
                  value={watch('startHours') || ''}
                  onChange={(event) =>
                    setValue('startHours', event.target.value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                  placeholder="hh:mm"
                />
              </div>
            </div>
          </div>

          <div
            className={styles.datePickerWrapper}
            data-testid="create-date-filter-wrapper"
          >
            <p className={styles.sectionLabel}>Data e hora final (opcional)</p>
            <div className={styles.datePickerInnerWrapper}>
              <div className={styles.fieldsInput}>
                <span className={styles.dateLabel}>De</span>
                <Datepicker
                  id="endDate"
                  initialValueInput={
                    watch('endDate')
                      ? convertDateToString(new Date(watch('endDate')))
                      : undefined
                  }
                  initialDate={new Date()}
                  onChangeInput={(event) =>
                    handleChangeScheduleDateInput('endDate', event)
                  }
                  onChangeDate={(date) =>
                    setValue('endDate', date?.getTime(), {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                  selectEnd
                  {...register('endDate')}
                />
              </div>
              <div className={styles.fieldsInput}>
                <span className={styles.dateLabel}>às</span>
                <Input
                  placeholder="hh:mm"
                  id="endHours"
                  {...register('endHours')}
                  type="time"
                  required
                  autoComplete="off"
                  value={watch('endHours') || ''}
                  onChange={(event) =>
                    setValue('endHours', event.target.value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.reasonWrapper}>
          <div>
            <Combobox
              searchable
              label={{ text: 'Motivo' }}
              {...register('reason')}
              placeholder="Selecione uma tratativa"
              items={predefinedOptionsCombobox}
              value={watch('reason')?.message}
              onChange={(item) => {
                const selected = item as ComboboxItem<Reason>
                setValue('reason', selected?.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }}
            />

            <div className={styles.textareaContainer}>
              <textarea
                id="textarea"
                className={styles.textarea}
                {...register('note')}
                rows={6}
                maxLength={5000}
                onChange={(e) => {
                  setValue('note', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }}
                placeholder="Descreva o serviço a ser realizado"
              />
            </div>
          </div>

          <Tags
            keyId={'#'}
            keyLabel={'#'}
            expandTags
            className={styles.tags}
            data={
              watch('reason')?.tags?.map((tag) => ({
                id: uuid(),
                name: tag,
              })) || []
            }
          />
        </div>
      </section>
    </div>
  )
}

export default GeneralInformation
